/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        /*
         function toggleMenu(e){
         e.preventDefault();
         $('#mobile_mainmenu').slideToggle(300);
         }

         $(document).on('click', '#mobileMenuBtn', toggleMenu);

         */

        var mobileMenuBtn = $('#mobileMenuBtn'),
          mobileMenuContainer = $('#mobile_mainmenu'),

          mobileSearchBtn = $('#mobileSearchBtn'),
          mobileSearchContainer = $('.rd-mobile-navigation-block .rd-sub-search'),

          mobileLangmenuBtn = $('#mobileLangmenuBtn'),
          mobileLangContainer = $('#langmenu.rd-sub-menu'),

          secondaryMobileMenu = $('nav.rd-secondary-navigation ul'),
          secondaryMobileMenuBtn = $('#secondaryMobileMenuBtn')

        // Remove functions

        function removeMobileMenuActive () {
          mobileMenuBtn.removeClass('active')
          mobileMenuContainer.removeClass('active')
        }

        function removeMobileSeachActive () {
          mobileSearchBtn.removeClass('active')
          mobileSearchContainer.removeClass('active')
        }

        function removeMobileLangActive () {
          mobileLangmenuBtn.removeClass('active')
          mobileLangContainer.removeClass('active')
        }

        function removeSecondaryMobileMenuB () {
          secondaryMobileMenu.removeClass('active')
          secondaryMobileMenuBtn.removeClass('active')
        }

        // Mobile Menu Btn

        if (mobileMenuBtn.length > 0) {

          mobileMenuBtn.on('click', function (e) {

            e.preventDefault()

            removeMobileSeachActive()
            removeMobileLangActive()

            if (mobileMenuBtn.hasClass('active')) {
              removeMobileMenuActive()
            } else {
              mobileMenuBtn.addClass('active')
              mobileMenuContainer.addClass('active')
            }
          })
        }

        // Mobile Search Btn

        if (mobileSearchBtn.length > 0) {

          mobileSearchBtn.on('click', function (e) {

            e.preventDefault()

            removeMobileMenuActive()
            removeMobileLangActive()

            if (mobileSearchBtn.hasClass('active')) {
              removeMobileSeachActive()
            } else {
              mobileSearchBtn.addClass('active')
              mobileSearchContainer.addClass('active')
            }
          })
        }

        // Mobile Lang Btn

        if (mobileLangmenuBtn.length > 0) {

          mobileLangmenuBtn.on('click', function (e) {

            e.preventDefault()

            removeMobileMenuActive()
            removeMobileSeachActive()

            if (mobileLangmenuBtn.hasClass('active')) {
              removeMobileLangActive()
            } else {
              mobileLangmenuBtn.addClass('active')
              mobileLangContainer.addClass('active')
            }
          })
        }

        // Mobile Secondary Menu Btn

        if (secondaryMobileMenuBtn.length > 0) {

          secondaryMobileMenuBtn.on('click', function (e) {

            e.preventDefault()

            if (secondaryMobileMenuBtn.hasClass('active')) {
              removeSecondaryMobileMenuB()
            } else {
              secondaryMobileMenu.addClass('active')
              secondaryMobileMenuBtn.addClass('active')
            }
          })
        }

        // Reset

        $('main.main').on('click', function (e) {

          /*

           if(mobileMenuBtn.hasClass('active')){
           mobileMenuBtn.removeClass('active');
           mobileMenuContainer.removeClass('active');
           }

           */

        })

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //mobile dropdown menu
        if ($('#mobile_mainmenu').length) {
          if ($('#mobile_mainmenu .menu-item-has-children').length) {
            $(document).click(function () {
              $(this).find('.dropper--items').removeClass('navshow')
            })
            $('#mobile_mainmenu .menu-item-has-children').click(function (e) {
              e.preventDefault()
              $(this).find('.dropper--items').toggleClass('navshow')
              e.stopPropagation()
            })
          }
        }

        // check subnavigation on page load and when browser resize
        if ($('.rd-secondary-navigation').length) {
          $(window).on('resize', function () {
            if ($(window).width() < 960) {
              $('#secondary-mobile').css('display', 'inline-block')
              $('#secondary-normal').css('display', 'none')
            } else if ($(window).width() >= 960 && $('#secondary-normal .rd-navigation-inner li').length > 3) {
              $('#secondary-mobile').css('display', 'inline-block')
              $('#secondary-normal').css('display', 'none')
            } else {
              $('#secondary-mobile').css('display', 'none')
              $('#secondary-normal').css('display', 'inline-block')
            }
          })
          if ($(window).width() < 960) {
            $('#secondary-mobile').css('display', 'inline-block')
            $('#secondary-normal').css('display', 'none')
          } else if ($(window).width() >= 960 && $('#secondary-normal .rd-navigation-inner li').length > 3) {
            $('#secondary-mobile').css('display', 'inline-block')
            $('#secondary-normal').css('display', 'none')
          } else {
            $('#secondary-mobile').css('display', 'none')
            $('#secondary-normal').css('display', 'inline-block')
          }
        }

        //click event for the secondary navigation
        $('#secondary-mobile .current_page_item a').append('<span class="icon-arrow-down"></span>')
        $('#secondary-mobile .current_page_item').addClass('subactive')
        $(document).click(function () {
          $('#secondary-mobile .rd-navigation-inner li').not('.current_page_item').removeClass('displaysub')
        })
        $('#secondary-mobile .current_page_item').click(function (e) {
          e.preventDefault()
          $('#secondary-mobile .rd-navigation-inner li').not('.current_page_item').toggleClass('displaysub')
          e.stopPropagation()
        })

        var $grid = $('.grid').imagesLoaded(function () {

          $(function () {
            $('.people-filter .profile-filter').matchHeight({
              byRow: 0
            })
          })

          // init Isotope after all images have loaded
          $('.grid').isotope({
            itemSelector: '.people-filter',
            layoutMode: 'fitRows'
          })

          // bind filter button click
          $('.filters-button-group').on('click', 'button', function () {
            var filterValue = $(this).attr('data-filter')
            // use filterFn if matches value
            $grid.isotope({filter: filterValue})
          })

          // change is-checked class on buttons
          $('.button-group').each(function (i, buttonGroup) {
            var $buttonGroup = $(buttonGroup)
            $buttonGroup.on('click', 'button', function () {
              $buttonGroup.find('.is-checked').removeClass('is-checked')
              $(this).addClass('is-checked')
            })
          })
        })

        //accordion
        $('.panel-heading').click(function () {
          var not = $(this).parent().find('.panel-collapse')
          $('.panel-collapse').not(not).slideUp()
          $('.panel-heading').not(this).removeClass('toggle')
          $(this).toggleClass('toggle')
          $(not).toggleClass('toggle')
          $(not).slideToggle()
        })

        $('.menu-item-type-custom>a').attr('download', true)

        // Google maps

        const isDraggable = $(document).width() > 768 ? true : false;

        (function ($) {

          function new_map ($el) {
            var add_marker
            var center_map
            var $markers = $el.find('.marker')
            var args = {
              zoom: 10,
              center: new google.maps.LatLng(0, 0),
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              scrollwheel: false,
              panControl: true,
              navigationControl: true,
              scaleControl: true,
              draggable: isDraggable,
              styles: [
                {
                  'featureType': 'administrative',
                  'elementType': 'labels.text.fill',
                  'stylers': [
                    {
                      'color': '#444444'
                    }
                  ]
                },
                {
                  'featureType': 'landscape',
                  'elementType': 'all',
                  'stylers': [
                    {
                      'color': '#f2f2f2'
                    }
                  ]
                },
                {
                  'featureType': 'poi',
                  'elementType': 'all',
                  'stylers': [
                    {
                      'visibility': 'off'
                    }
                  ]
                },
                {
                  'featureType': 'road',
                  'elementType': 'all',
                  'stylers': [
                    {
                      'saturation': -100
                    },
                    {
                      'lightness': 45
                    }
                  ]
                },
                {
                  'featureType': 'road.highway',
                  'elementType': 'all',
                  'stylers': [
                    {
                      'visibility': 'simplified'
                    }
                  ]
                },
                {
                  'featureType': 'road.arterial',
                  'elementType': 'labels.icon',
                  'stylers': [
                    {
                      'visibility': 'off'
                    }
                  ]
                },
                {
                  'featureType': 'transit',
                  'elementType': 'all',
                  'stylers': [
                    {
                      'visibility': 'off'
                    }
                  ]
                },
                {
                  'featureType': 'water',
                  'elementType': 'all',
                  'stylers': [
                    {
                      'color': '#46bcec'
                    },
                    {
                      'visibility': 'on'
                    }
                  ]
                }
              ]
            }
            var map = new google.maps.Map($el[0], args)
            map.markers = []
            $markers.each(function () {
              add_marker($(this), map)
            })
            center_map(map)
            return map
          }

          function add_marker ($marker, map) {
            var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'))
            var marker = new google.maps.Marker({
              position: latlng,
              map: map
            })
            map.markers.push(marker)
            if ($marker.html()) {
              var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
              })
              google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker)
              })
            }
          }

          function center_map (map) {
            var bounds = new google.maps.LatLngBounds()
            $.each(map.markers, function (i, marker) {
              var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng())
              bounds.extend(latlng)
            })
            if (map.markers.length === 1) {
              map.setCenter(bounds.getCenter())
              map.setZoom(14)
            }
            else {
              map.fitBounds(bounds)
            }
          }

          var map = null
          $(document).ready(function () {
            $('.acf-map').each(function () {
              map = new_map($(this))
            })
          })

        })(jQuery)
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  }

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire
      var namespace = Sage
      funcname = (funcname === undefined) ? 'init' : funcname
      fire = func !== ''
      fire = fire && namespace[func]
      fire = fire && typeof namespace[func][funcname] === 'function'

      if (fire) {
        namespace[func][funcname](args)
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common')

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm)
        UTIL.fire(classnm, 'finalize')
      })

      // Fire common finalize JS
      UTIL.fire('common', 'finalize')
    }
  }

  // Load Events
  $(document).ready(UTIL.loadEvents)

})(jQuery) // Fully reference jQuery after this point.
